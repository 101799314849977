import { roleId1, roleId2 } from "./roleId"

export const adminPremission = () => {
    let adminRole = localStorage.getItem('role')
    if(adminRole === roleId1 || adminRole === roleId2){
        return true
    } 
    else{
        return false
    }
}

export const executivePermission =()=>{
    let adminRole = localStorage.getItem('role')
    if(adminRole === roleId2){
        return true
    } 
    else{
        return false
    }
}