import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { useDispatch, useSelector } from 'react-redux'
import { setMessage, removeMessage } from '../store/alertMessage/actions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { setCityList, setCountryList, setPincodeList, setStateList} from '../store/commonlist/actions'
import { apiUrl } from '../apiurl'

const App = () => {
  const API_URL = apiUrl
  const STATE_URL = `${API_URL}/states`
  const CITY_URL = `${API_URL}/cities`
  const PINCODE_URL = `${API_URL}/pincodes`
  const COUNTRY_URL = `${API_URL}/countries`
  const alertMessage = useSelector((state: any) => state.alertMessage.message)
  const alertStatus = useSelector((state: any) => state.alertMessage.status)
  const alertTime = useSelector((state: any) => state.alertMessage.time)
  const isLoggedIn = useSelector((state:any)=>state.authDetail.isLoggedIn)
  const dispatch = useDispatch();

  let dummyCity = [
    {"id": "0b81a1b9-e7ef-11ed-8499-001cc0bad95b", "name": "Ahmedabad", "state_id": "ba3af387-e7ee-11ed-8499-001cc0bad95b"},
    {"id": "71d35a63-91bd-4c7e-a4e5-7c9744c561e5", "name": "Surat", "state_id": "ba3af387-e7ee-11ed-8499-001cc0bad95b"}
  ]

  const getCountry = async () => {
    await axios.get(`${COUNTRY_URL}?sort=name&order=ASC`).then(({ data }: any) => {
      dispatch(setCountryList(data?.responseData?.map((x: any) => {
        return {
          label: x.name,
          value: x.id,
          ...x
        }
      })))
    }).catch((error) => {
      dispatch(setMessage("error", error.message))
    })
  }

  const getState = async () => {
    await axios.get(`${STATE_URL}?sort=name&order=ASC`).then(({ data }: any) => {
      dispatch(setStateList(data?.responseData?.map((x: any) => {
        return {
          label: x.name,
          value: x.id,
          ...x
        }
      })))
    }).catch((error) => {
      dispatch(setMessage("error", error.message))
    })
  }

  const getCity = async () => {
    await axios.get(`${CITY_URL}?sort=name&order=ASC`).then(({ data }: any) => {
      dispatch(setCityList(data?.responseData?.map((x: any) => {
        return {
          label: x.name,
          value: x.id,
          ...x
        }
      })))
    }).catch((error) => {
      dispatch(setMessage("error", error.message))
    })
  }

  const getPincode = async () => {
    await axios.get(`${PINCODE_URL}?sort=name&order=ASC`).then(({ data }: any) => {
      dispatch(setPincodeList(data?.responseData?.map((x: any) => {
        return {
          label: x.name,
          value: x.id,
          ...x
        }
      })))
    }).catch((error) => {
      dispatch(setMessage("error", error.message))
    })
  }

  

  useEffect(() => {
    if(isLoggedIn){
      getCountry()
      getState()
      getCity()
      getPincode()
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (alertMessage || alertMessage !== "") {
      let message = alertMessage
      let status = alertStatus
      let time = alertTime
      dispatch(removeMessage())
      if (status == "success") {
        toast.success(message, {
          toastId: message,
          position: "top-center",
          autoClose: time * 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      else if (status == "error") {
        toast.error(message, {
          toastId: message,
          position: "top-center",
          autoClose: time * 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }, [alertMessage])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ToastContainer limit={1}/>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
