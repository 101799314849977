import { commonListAction } from "./types";

export const setCountryList = (payload) => {
    return {
        type: commonListAction.SET_COUNTRY_LIST,
        payload
    };
}

export const setStateList = (payload) => {
    return {
        type: commonListAction.SET_STATE_LIST,
        payload
    };
}

export const setCityList = (payload) => {
    return {
        type: commonListAction.SET_CITY_LIST,
        payload
    };
}     

export const setPincodeList = (payload) => {
    return {
        type: commonListAction.SET_PINCODE_LIST,
        payload
    };
} 

export const setCategoryList = (payload) => {
    return {
        type: commonListAction.SET_CATEGORY_LIST,
        payload
    };
}

export const setProductList = (payload) => {
    return {
        type: commonListAction.SET_PRODUCT_LIST,
        payload
    };
}