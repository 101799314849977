import { combineReducers } from "redux";
import { authDetailReducer } from "./authDetail/reducers";
import { alertMessageReducer } from "./alertMessage/reducers";
import { commonListReducer } from "./commonlist/reducers";

const reducers = combineReducers({
    authDetail: authDetailReducer,
    alertMessage: alertMessageReducer,
    commonList: commonListReducer,
})

export default reducers;