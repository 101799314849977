/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {adminPremission, executivePermission} from '../../../../../utils/role'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/graphs/gra010.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div> */}
      {adminPremission() ? (
        <SidebarMenuItemWithSub
          to='/management'
          title='Management'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen024.svg'
        >
          {/* <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub> */}
          <SidebarMenuItem
            to='/management/distributor-management/distributors'
            title='Distributor Management'
            hasBullet={true}
          />

          <SidebarMenuItem
            to='/management/servicecenter-management/servicecenters'
            title='Service Center Management'
            hasBullet={true}
          />
          {executivePermission() ? (
            <></>
          ) : (
            <SidebarMenuItem
              to='/management/customersupport-management/customersupports'
              title='Customer Support Executive'
              hasBullet={true}
            />
          )}

          {/* <SidebarMenuItem
            to='/management/supportagent-management/supportagents'
            title='Support Agent'
            hasBullet={true}
          /> */}
          <SidebarMenuItem
            to='/management/category-management/categorymanagements'
            title='Category Management'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/management/product-management/productmanagements'
            title='Product Management'
            hasBullet={true}
          />

          <SidebarMenuItem
            to='/management/model-management/modelmanagements'
            title='Model Management'
            hasBullet={true}
          />

          <SidebarMenuItem
            to='/management/part-management/partmanagements'
            title='Parts Management'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/management/customer-management/customermanagements'
            title='Customer Management'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      ) : (
        ''
      )}
      <SidebarMenuItem
        to='ticket-management/ticketmanagements'
        title='Support Ticket Management'
        icon='/media/icons/duotune/abstract/abs033.svg'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/claim-management/claimmanagements'
        title='Claim Management'
        icon='/media/icons/duotune/general/gen012.svg'
        fontIcon='bi-app-indicator'
      />
      {adminPremission() ? (
        <SidebarMenuItem
          to='sendreceivespareparts-management/sendreceivespareparts'
          title='Send Receive Spare Parts'
          icon='/media/icons/duotune/arrows/arr034.svg'
          fontIcon='bi-app-indicator'
        />
      ) : (
        <SidebarMenuItem
          to='sendreceivespareparts-management/sendreceivespareparts'
          title='Request Spare Parts'
          icon='/media/icons/duotune/arrows/arr034.svg'
          fontIcon='bi-app-indicator'
        />
      )}

      {adminPremission() ? (
        <SidebarMenuItem
          to='productreturn-management/productreturnmanagements'
          title='Product Return Report'
          icon='/media/icons/duotune/arrows/arr034.svg'
          fontIcon='bi-app-indicator'
        />
      ) : (
        <></>
      )}

      {adminPremission() ? (
        <SidebarMenuItemWithSub
          to='/report'
          title='Reports'
          icon='/media/icons/duotune/general/gen005.svg'
          fontIcon='bi-person'
        >
          <SidebarMenuItem
            to='/report/supportticketarchive/Supportticketarchivereports'
            title='Support Ticket Archive Report'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/report/claimarchive/ClaimarchiveReports'
            title='Claim Archive Report'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/report/sentreceiveparts/SentreceivepartsReports'
            title='Parts Received vs Sent'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/report/pendingtoreceivefromservicecenter/PendingtoreceivepartsReports'
            title='Parts Pending To Receive From Service Center'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/report/supportticketsanalysisbytickettype/SupportticketanalysisReports'
            title='Support Ticket Analysis'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      ) : (
        ''
      )}
      <SidebarMenuItem
        to='/panelsetting/PanelSettingPage'
        title='Setting'
        icon='/media/icons/duotune/coding/cod001.svg'
      />

      {/* <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>*/}
      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />  */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
