import {useEffect, useState} from 'react'
import {object, string, ref} from 'yup'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword} from '../core/_requests'
import {useDispatch} from 'react-redux'
import {setMessage} from '../../../../store/alertMessage/actions'

const initialValues = {
  token: '',
  newPassword: '',
  confirmPassword: '',
}

const getCharacterValidationError = (str: string) => {
  return `Your password must have at least 1 ${str} character`
}

const resetPasswordSchema = object({
  newPassword: string()
    .required('Please enter a password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/,
      'Set the strong password include [  Uppercase, Lowercase, Number, Special symbol ] and more than 7 letter.'
    ),
  confirmPassword: string()
    .required('Please re-type your password')
    .oneOf([ref('newPassword')], 'Passwords does not match'),
})

export function ResetPassword() {
  const navigate = useNavigate()
  const param = useParams()
  const id = param.id
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      try {
        setLoading(true)
        resetPassword(values)
          .then((data: any) => {
            if (data.responseStatus == 200) {
              dispatch(setMessage('success', data.responseMessage))
              setTimeout(() => {
                navigate('/auth/login')
              }, 2500)
            } else {
              dispatch(setMessage('error', data.responseMessage))
            }
            setLoading(false)
          })
          .catch((error: any) => {
            if (error?.response?.data?.responseData?.msg) {
              dispatch(setMessage('error', error?.response?.data?.responseData?.msg))
            } else if (error?.response?.data?.responseMessage) {
              dispatch(setMessage('error', error?.response?.data?.responseMessage))
            } else {
              dispatch(setMessage('error', error?.message))
            }
            setLoading(false)
          })
      } catch (error: any) {
        dispatch(setMessage('error', error.message ? error.message : 'Something Went Wrong'))
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    formik.setFieldValue('token', id)
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Reset Password ?</h1>
        {/* end::Title */}
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder  text-gray-900 fs-6'>New Password</label>
        <input
          type='password'
          placeholder='New Password'
          autoComplete='off'
          className={'form-control bg-transparent'}
          onChange={(e: any) => {
            formik.setFieldValue('newPassword', e.target.value.trimStart())
          }}
          value={formik?.values?.newPassword}
        />
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.newPassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder  text-gray-900 fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Confirm Password'
          autoComplete='off'
          className={'form-control bg-transparent'}
          onChange={(e: any) => {
            formik.setFieldValue('confirmPassword', e.target.value.trimStart())
          }}
          value={formik?.values?.confirmPassword}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
    </form>
  )
}
